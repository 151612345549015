<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('podrForm')"
        @cancel="cancel"
    ></add-header>
    <el-form ref="podrForm" :rules="rules" :model="podrForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="订单编号" prop="podr_no">
            <el-input v-model="podrForm.podr_no" clearable maxlength="30"  show-word-limit placeholder="请填写订单编号">
            </el-input>
          </el-form-item>
          <el-form-item label="交货时间" prop="podr_date">
            <el-date-picker
              :disabled="!specialDataFlag"
              v-model="podrForm.podr_date"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购方" prop="cptt_id">
            <el-select v-model="podrForm.cptt_id" placeholder="请选择采购方" clearable>
              <el-option
                  v-for="item in cpttFctrGroupOpt"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="总金额" prop="podr_total">
            <el-input v-model="podrForm.podr_total" disabled maxlength="11" @input ="podrForm.podr_total = helper.keepTNum1(podrForm.podr_total)" @blur ="podrForm.podr_total = helper.haveFour(podrForm.podr_total,2,100000000)" show-word-limit placeholder="请填写总金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="销售方" prop="podr_cptt_id">
            <el-select v-model="podrForm.podr_cptt_id" placeholder="请选择销售方" clearable>
              <el-option
                  v-for="item in cpptForm"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="其他金额" prop="extra_price">
            <el-input @change="extraPriceChange" v-model="podrForm.extra_price" maxlength="9" @input ="podrForm.extra_price = helper.keepTNum1(podrForm.extra_price)"  show-word-limit placeholder="请填写其他金额"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" @click="addRow()">导入</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>
      <el-row class="vd_mrt">
        <PodrAddProd :isShow="false" @handleSelectionChange="handleSelect" :prodForm="podrForm"></PodrAddProd>
      </el-row>
      <el-dialog
        :title="title"
        :visible.sync="notice"
        width="70%">
        <PodrAddEditIn @cancelPodrIn="cancelPodrIn" @confirmIn="confirmIn" ref="PodrAddEditIn"></PodrAddEditIn>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
     </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {podrAPI} from "@api/modules/podr";
import {prodAPI} from "@api/modules/prod";
import {acctAPI} from "@api/modules/acct";
import {cpttAPI} from "@api/modules/comptitle";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import PodrAddEditIn from "@/views/PurchaseManagement/PodrManage/TabChild/Componet/PodrAddEditIn"
import PodrAddProd from "@/views/PurchaseManagement/PodrManage/TabChild/Componet/TabPanel/PodrAddProd"

export default {
  name: "smplAddMain",
  components: {
    addHeader,
    inputUser,
    PodrAddEditIn,
    PodrAddProd,
  },
  data() {
    return {
      rules:{
        podr_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        podr_cptt_id:[
          { required: true, trigger: 'change' , message: ' '},
        ],
        cptt_id:[
          { required: true, trigger: 'change' , message: ' '},
        ],
        podr_total:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        extra_price:[ {
            pattern: /^\d{0,4}(\.\d{1,4})?$/,
            message: "最大只能输入9999.9999并且只能输入数字且最多4位小数!",   
          },]
      },
      podrForm:{
        podr_no: '',
        podr_cptt_id: '',
        podr_cptt_name: '',
        cptt_id: '',
        cptt_name: '',
        podr_date: '',
        podr_total:0,
        extra_price:'',
        podr_prod_list:[
        ],
        key:0,
      },
      notice:false,
      podrProdLi:[],
      removePodrProdLi:[],
      cpptForm:[],
      cpttFctrGroupOpt:[],
      title:'产品信息表',
      specialDataFlag:false
    }
  },
  watch: {
  },
  created() {
    this.initData()

  },
  methods:{
     initData(){
       this.getCppt()
       this.getCpttPurchaser()
       if(this.$cookies.get('userInfo').acct_id === 1){
        this.specialDataFlag = true
      }
     },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    // 保存
    saveInfo() {
      if(this.podrForm.podr_prod_list.length<1){
        return this.$message.warning('至少添加一条产品')
      }
      let podrForm = {};
      podrForm = Object.assign(podrForm,this.podrForm);
      let str1 = null;
      let str2 = null;
      this.cpptForm.forEach(item=>{
        if(item.cptt_id === this.podrForm.podr_cptt_id){
          str2 = item.cptt_name
        }
      })
      this.cpttFctrGroupOpt.forEach(item=>{
        if(item.cptt_id === this.podrForm.cptt_id){
          str1 = item.cptt_name
        }
      })
      podrForm.cptt_name = str1;
      podrForm.podr_cptt_name = str2;
      podrForm.podr_date = (new Date(this.podrForm.podr_date)).getTime() / 1000;
      const staffForm =  this.$refs.userData.getData()
      podrForm = Object.assign(podrForm,staffForm)
      post(podrAPI.addPodr,podrForm)
      .then(res => {                                                    
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          const permId = this.$route.query.perm_id
          this.jump(`/podr_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
          this.resetForm('podrForm')
        }else if(res.data.code === 7){
          this.$message({
            type:'error',
            message:'单据编号重复'
          })
          this.podrForm.podr_date = (new Date(this.podrForm.podr_date)).getTime() * 1000;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
          this.podrForm.podr_date = (new Date(this.podrForm.podr_date)).getTime() * 1000;
          this.initData()
        }
      })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('podrForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      })
      .catch(()=>{})
    },
    //删除选择的内容
    handleSelect(val){
      this.removePodrProdLi = val;
    },
    // 修改其他金额改变总金额
    extraPriceChange(){
      this.podrForm.extra_price = this.helper.calcPrice(this.podrForm.extra_price,4,10000)
      let temp = 0;
      if(this.podrForm.podr_prod_list.length>0){
        for(let i=0;i<this.podrForm.podr_prod_list.length;i++){
          if(this.podrForm.podr_prod_list[i].podr_prod_num!==undefined && this.podrForm.podr_prod_list[i].podr_prod_price!==undefined){
            let num1 = Number(this.podrForm.podr_prod_list[i].podr_prod_num)*100000
            let num2 = Number(this.podrForm.podr_prod_list[i].podr_prod_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
      }
      if(this.podrForm.extra_price){
        temp = temp + Number(this.podrForm.extra_price)
      }
      this.podrForm.podr_total = this.helper.haveFour(Number(temp));
    },
    // 删除产品信息按钮
    delBank(){
      if(this.podrForm.podr_prod_list.length <= 0){
        return this.$message.warning('至少选择一条数据')
      }
      this.podrForm.podr_prod_list = this.podrForm.podr_prod_list.filter(item => this.removePodrProdLi.every(item1 => item.prod_id != item1.prod_id));
      let temp = 0;
      if(this.podrForm.podr_prod_list.length>0){
        for(let i=0;i<this.podrForm.podr_prod_list.length;i++){
          if(this.podrForm.podr_prod_list[i].podr_prod_num!==undefined && this.podrForm.podr_prod_list[i].podr_prod_price!==undefined){
            let num1 = Number(this.podrForm.podr_prod_list[i].podr_prod_num)*100000
            let num2 = Number(this.podrForm.podr_prod_list[i].podr_prod_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
      }
      if(this.podrForm.extra_price){
        temp = temp + Number(this.podrForm.extra_price)
      }
      this.podrForm.podr_total = this.helper.haveFour(Number(temp));
    },
    // 打开导入产品信息小窗口
    addRow(){
      this.notice = true;
    },
    // 导入产品信息小窗口确定按钮
    confirmIn(val){
      this.podrProdLi = val;
      this.notice = false;
      this.podrProdLi = this.podrProdLi.filter(item => this.podrForm.podr_prod_list.every(item1 => item.prod_id != item1.prod_id));
      this.podrForm.podr_prod_list = this.podrForm.podr_prod_list.concat(this.podrProdLi);
      this.$refs.PodrAddEditIn.clear()
    },
    // 取消选择
    cancelPodrIn(){
      this.notice = false
    },
    // 获取采购方
    getCpttPurchaser(){
      get(cpttAPI.get_my_cptts_v1)
      .then(res=>{
        if(res.data.code === 0){
          this.cpttFctrGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取合作单位信息
    getCppt(){
      get(cpttAPI.getAllCpttsV1)
      .then(res=>{
        if (res.data.code === 0) {
          this.cpptForm = res.data.data;
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.podrForm.podr_prod_list = [];
    },
  }
}
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_eltab {
  margin-top: 10px;
}
</style>